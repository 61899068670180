










































import BaseMixin from '@/misc/BaseMixin.mixins';
import Review from '@/models/Review.model';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DealReviewsComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public isLoading!: boolean;

  @Prop({ required: true })
  public reviews!: Review[];

  @Prop()
  public averageRating?: number;

  @Prop({ required: true })
  public deleteReview!: (reviewId: string) => void;
}
